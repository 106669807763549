.Description {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #2F1160;
    padding: 20px 0 40px;
}

.games {
    padding: 20px 0 40px;
}

@media screen and (max-width: 800px) {
    .Description {
        padding: 30px 0;
        font-size: 22px;
    }
}

@media screen and (max-width: 600px) {
    .Description {
        padding: 25px 0;
        font-size: 20px;
        line-height: 1.2;
    }
}

@media screen and (max-width: 400px) {
    .Description {
        font-size: 17px;
    }
}