.App {
    background:
        url('../src/images/background.png') no-repeat 100% 100%,
        #FFFFFF;
}

@media screen and (max-width: 1000px) {
    .App {
        background:
            url('../src/images/background.png') no-repeat 100% 120%,
            #FFFFFF;

    }
}

@media screen and (max-height: 800px) {
    .App {
        background:
            url('../src/images/background.png') no-repeat 100% 140%,
            #FFFFFF;

    }
}

@media screen and (max-height: 700px) {
    .App {
        background: #FFFFFF;
    }
}