.UserInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.UserName {
    font-size: 17px;
    color: #2F1160;
}

.UserBalance {
    font-weight: bold;
    font-size: 17px;
    text-align: right;
    color: #2F1160
}

.UserImage {
    margin-left: 10px;
    max-width: 80px;
}

@media screen and (max-width: 600px) {
    .UserName {
        font-size: 14px;
    }

    .UserBalance {
        font-size: 14px;
    }
}

@media screen and (max-width: 550px) {
    .UserName {
        font-size: 12px;
    }

    .UserBalance {
        font-size: 12px;
    }

    .UserInfo {
        justify-content: center;
        gap: 8px;
    }
}