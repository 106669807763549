* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  min-width: 320px;
  overflow-x: hidden;

  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 320px) {
  body {
    overflow: unset;
  }
}
