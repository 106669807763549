.Title {
    font-weight: bold;
    font-size: 62px;
    text-align: center;
    color: #2F1160;
    margin: 0 auto;
    width: 100%;
}

.Title span {
    font-weight: bold;
    font-size: 50px;
    line-height: 1.6;
    text-align: center;


    background: linear-gradient(180deg, #C44FC8 52.66%, #843EF1 100%),
    linear-gradient(0deg, #2F1160, #2F1160);
    background-size: 100%;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent
}

.game {
    font-size: 53px;
    padding-top: 160px;
}

@media screen and (max-width: 1000px) {
    .Title,
    .Title span {
        font-size: 60px;
        line-height: 1.2;
    }
}

@media screen and (max-width: 800px) {
    .Title,
    .Title span {
        font-size: 50px;
    }
}

@media screen and (max-width: 700px) {
    .Title,
    .Title span {
        font-size: 37px;
    }
}

@media screen and (max-height: 600px) and (max-width: 900px) {
    .Title,
    .Title span {
        font-size: 37px;
        line-height: 1.2;
    }
}