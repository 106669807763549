.FormWrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.FormWrapper h1 {
  line-height: 108px;
}

@media screen and (max-width: 1000px) {
  .FormWrapper h1 {
    font-size: 38px !important;
  }
}

@media screen and (max-width: 500px) {
  .FormWrapper h1 {
    font-size: 32px !important;
  }
}