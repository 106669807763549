.Home {
    width: 100%;
    max-width: 740px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5px;
}

.GameButton {
    width: 130px;
    height: 40px;
    animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
    from {
        transform: scale(.85);
    }

    50% {
        transform: scale(1);
    }

    to {
        transform: scale(.85);
    }
}