.Layout {
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Main {
  position: relative;
  width: 100dvw;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.LayoutWithoutHeader {
  height: 100dvh;
}
