.Currency {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 0px 30px;
}

.CurrencyTitle {
    font-size: clamp(30px, 9.5vw, 72px);
    font-weight: 700;
    text-align: center;
    color: #2F1160;
    white-space: nowrap;
}

.Currency button{
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 18px;
}

.DropList {
    margin-top: 90px;
    margin-bottom: 55px;
}

@media screen and (max-width: 700px) {
    .DropList {
        margin-top: 70px;
    }
}
