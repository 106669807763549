.Header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  max-height: 82px;
  overflow: hidden;
  transition: max-height 0.3s linear;
}

.Header.Hidden {
  max-height: 0;
}

.HeaderContent {
  padding: 20px 10px;
  width: 100%;
  max-width: 1620px;
  margin: 0 auto;
  display: flex;
  justify-content: right;
  align-items: center;
}

.Header a {
  width: 100%;
  max-width: 170px;
  position: relative;
}

.HeaderButtonsDiv {
  display: flex;
  gap: 10px;
}

.HeaderButtonsDiv button {
  width: 120px;
  height: 50px;
  font-weight: 700;
}

.UserActionsWrapper {
  display: flex;
  gap: 20px;
}

.UserActionsButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.SettingsImage {
  width: 28px;
  height: 30px;
}

.UserActionsButtons button {
  width: 120px;
  height: 50px;
  font-weight: 700;
}

@media screen and (max-width: 850px) {
  .HeaderButtonsDiv button {
    width: 100px;
    height: 40px;
    font-size: 14px;
  }
}

@media screen and (max-width: 550px) {
  .HeaderButtonsDiv button {
    width: 80px;
    font-size: 10px;
  }

  .UserActionsButtons button {
    width: 100px;
    font-size: 12px;
  }

  .UserActionsWrapper {
    gap: 10px;
  }
}

@media screen and (max-width: 550px) {
  .HeaderContent a {
    width: 150px;
  }
}

@media screen and (max-width: 400px) {
  .HeaderContent a {
    width: 120px;
  }
}
