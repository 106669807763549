.Games {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.Games h1 {
  font-weight: bold;
  font-size: 53px;
  line-height: 120%;
  text-align: center;
  color: #2f1160;
  padding-top: 160px;
}

.Games.GamesGame h1 {
  padding-top: 0;
}

.Games ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Games li {
  width: 365px;
  height: 402px;
  background: #ffffff;
  border-radius: 10px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.GameItem {
  border-radius: 10px;
  max-width: 365px;
  max-height: 402px;
  width: 100%;
  height: 100%;
  transition: 0.2s ease;
}

.GameHover {
  border-radius: 10px;
  height: 100%;
  opacity: 0;
  transition: 0.4s ease;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  text-align: center;
  backdrop-filter: blur(10px) brightness(40%);
}

.GameTitle {
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  padding-top: 25px;
}

.GameTitleLong {
  font-size: 26px;
}

.GameSubtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  padding-bottom: 30px;
}

.GameDescription {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
  padding: 15px 18px;
  border-bottom: 1px solid #f6f6fe;
  border-top: 1px solid #f6f6fe;
}

.GameButtons {
  display: flex;
  justify-content: space-around;
  padding-top: 44px;
}

.GameButtons a {
  width: 100%;
}

.ButtonDemo {
  max-width: 155px;
  max-height: 50px;
  width: 100%;
  border-radius: 4px;
  padding: 11.5px 0;
  cursor: pointer;
  border: 2px solid #6200ff;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
  background: transparent;
}

.ButtonReal {
  max-width: 155px;
  max-height: 50px;
  width: 100%;
  border-radius: 4px;
  padding: 12.5px 0;
  cursor: pointer;
  background: #6200ff;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
}

.GameHover:hover {
  opacity: 1;
}

.GameWrapper {
  padding-top: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.GameWrapper a {
  position: absolute;
  left: 0;
  max-width: 50px;
  max-height: 50px;
}

.Frame {
  width: 100%;
  height: 100%;
  background: white;
}

@media screen and (max-width: 600px) {
  .Games h1 {
    font-size: 40px;
    padding-top: 120px;
  }
}

@media screen and (max-width: 450px) {
  .Games h1 {
    font-size: 30px;
  }

  .Games li {
    width: 300px;
    height: 285px;
  }

  .GameTitle {
    font-size: 28px;
    line-height: 1.2;
    padding-top: 10px;
  }

  .GameSubtitle {
    font-size: 14px;
    line-height: 1.2;
    padding-bottom: 10px;
  }

  .GameDescription {
    line-height: 1.3;
    font-size: 14px;
    padding: 10px;
  }

  .GameTitleLong {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .GameWrapper a {
    max-width: 35px;
    max-height: 35px;
  }
}

@media screen and (max-width: 400px) {
  .GameWrapper a {
    max-width: 25px;
    max-height: 25px;
  }

  .GameTitle {
    font-size: 23px;
  }

  .GameTitleLong {
    font-size: 16px;
  }
}
