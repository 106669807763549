.Auth {
    min-height: 80vh;
    display: flex;
    max-width: 740px;
    margin: 0 auto 3rem;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

.AuthForm {
    display: flex;
    align-items: baseline;
    width: 100%;
}

.AuthForm div {
    width: 100%;
}

.AuthForm div:nth-child(2) {
    margin-left: 7px;
}

.AuthForm button {
    max-width: 96px;
    margin-left: 7px;
}

@media screen and (max-width: 800px) {
    .AuthForm {
        flex-direction: column;
    }

    .AuthForm div:nth-child(2) {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .AuthForm button {
        margin-left: 0;
    }

    .AuthForm a {
        margin: 0 auto;
    }
}
